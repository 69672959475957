import './App.css';
import React from 'react';
import Provider from './Provider';
import Page from './page';
function App() {
  return (
    <div className="App">
      <Provider>
        <Page />
      </Provider>
    </div>
  );
}

export default App;
